@import '../src/designTokens/css-variables.scss';


/***    General     ***/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif
}

body {
  margin: 0 auto
}

.m-5-auto {
  margin: 5rem auto
}
::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  background-color: #dee4e8 !important;
  -moz-appearance: none;
  z-index: 10;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  width: 5px !important;
  height: 5px !important;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2) !important;
  background-color: #dee4e8 !important;
  -moz-appearance: none;
  z-index: 10;
}

.primary-button {
  margin-top: 5rem;
  margin-right: 1rem;
  padding: .6rem;
  width: 10rem;
  background: #222;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  transition: all .5s;
  cursor: pointer;
  text-transform: capitalize
}

/***    Landing Page     ***/
.main-title,
.main-para {
  color: #f1f1f1
}

.main-title {
  padding-top: 10rem;
  font-size: 5rem;
  font-family: 'Fascinate', cursive;
  text-transform: uppercase
}

.main-para {
  font-size: 2.5rem;
  text-Transform: capitalize
}

#reg_btn span {
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

#reg_btn span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

#reg_btn:hover span {
  padding-right: 25px;
}

#reg_btn:hover span:after {
  opacity: 1;
  right: 0;
}

/***    Login Page     ***/
h2 {
  font-weight: 300
}

// form {
//   display: inline-block;
//   background: #f3f3f3;
//   border: 1px solid #ddd;
//   border-radius: 2px;
//   padding: 2rem;
//   margin: 2rem 0 1rem 0
// }

// form label {
//   float: left;
//   font-size: .9rem;
//   margin: 0;
//   padding: 0
// }

// .right-label {
//   float: right;
//   cursor: pointer
// }

// input {
//   width: 15rem;
//   padding: .3rem;
//   border-radius: 5px;
//   outline: none;
//   border: none
// }

#sub_btn {
  display: block;
  width: 100%;
  padding: .3rem;
  border: none;
  background: #222;
  color: #fff;
  border-radius: 3px;
}

#sub_btn:hover {
  background: #333;
  transition: all .5s
}

footer p {
  margin: 0;
  font-size: .9rem
}

/***    Register Page     ***/
#checkbox {
  width: 1rem
}

form span {
  font-size: .8rem
}

#reset_pass_lbl {
  float: left
}

/***    Home Page     ***/
.home-page-title {
  color: #222
}

// .containermain {
  /* display: grid; */
  /* grid-template-columns: 181px 1fr; */

  /* justify-content: space-around; */
  /* margin-right: 10px; */
  /* width: 100%; */

// }

button:focus{
  outline: none !important;
}
.control-wrapper .MuiFormControl-root {
  width: 100%;
}
.control-wrapper {
  padding: 6px 0;
  flex-wrap: wrap;
  width: 100%;
}
.auth-form-container {
  width: 100%;
  max-width: 450px;
  margin: auto;
  margin-top: 30px;
  padding: 16px;
  border: 1px solid #ddd;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  background-color: #fff;
}
.text-link {
  cursor: pointer;
  font-size: 12px;
  color: var(--color-secondary);
}

.public-wrapper{
  background-color: var(--color-secondary);
  height: 100vh;
  width: 100vw;
}

.app-wrapper{
  height: 100vh;
  overflow: hidden;
}