.popup-container{ 
    width: 1080px;
    max-width: 100%;
    .activity-collapse{
        word-break: break-all;
    }
    .MuiTableCell-root.success {
        color: green;
    }
    .MuiTableCell-root.error {
        color: red;
    }
}