.sidebar {
    background-color: var(--color-secondary);
    width: 75px;
    overflow-x: hidden;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.sidebar.expanded {
    width: 265px;
}

.bar-header .when-opened {
    margin: auto;
    width: 150px;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);

    img {
        display: block;
        max-height: 46px;
        margin: auto;
    }
}

.bar-header .when-closed {
    width: 46px;
    margin: auto;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.sidebar .when-opened,
.sidebar.expanded .when-closed {
    display: none !important;
}

.sidebar.expanded .when-opened,
.sidebar .when-closed {
    display: block !important;
}

.sidebar .navigations {
    padding-right: 10px !important;
}

.sidebar.expanded .navigations {
    padding-right: 12px !important;
}

.navigation-link {
    margin-bottom: 12px;

    & * {
        color: #fff;
    }

    a {
        display: block;
        padding: 3px 2px;
        border-radius: 0 4px 4px 0;

        & .link-content {
            border-left: 3px solid transparent;
        }

        &.active-link,
        &.active,
        &:hover {
            background-color: var(--color-hover);

            & .link-content {
                background-color: var(--color-hover);
                border-left: 3px solid #fff;

                & * {
                    color: #fff !important;
                }
            }
        }
    }


    &:hover .link-content {
        background-color: #fff;
        border-left: 3px solid var(--color-secondary);
    }

    &:hover * {
        color: var(--color-primary) !important;
    }

    p {
        font-size: 0.9rem;
        white-space: nowrap;
        font-weight: 500;
    }

}

.navigations .link-content {
    padding-left: 32%;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 0 4px 4px 0;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.sidebar.expanded .navigations .link-content {
    padding-left: 10px;
}


.navigation-link .icon .MuiSvgIcon-root,
.logout .MuiSvgIcon-root {
    font-size: 1.5rem;
}

.link-name {
    padding-left: 10px;
}

.rotate-text {
    font-size: 0.9rem;
    transform-origin: center;
    transform: rotate(-90deg) translateX(30px);
    white-space: nowrap;
    padding-left: 10px;
    font-weight: 500;
}

.logout,
.user-avatar>div {
    background-color: transparent;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    margin: auto;
    overflow: hidden;
}

.logout .MuiSvgIcon-root {
    color: #fff;
}

.logout-link {
    background-color: transparent;
    padding: 0 !important;
    font-size: 0.8rem;
    color: var(--color-primary);
    text-decoration: none !important;
    border: none;
}

