.app-header {
    background-color: #FFF;
    border-bottom: 1px solid #eee;
    height: 63px;
    position: relative;

    .page-title {
        p {
            font-weight: 600;
        }
    }

    .pl-3 {
        padding-left: 30px;
    }
    .btn-outline-dark:focus {
        box-shadow: none;
    }
}

.toggler {
    background-color: transparent;
    border: none;
    outline: none !important;
}

.user-brand,
.page-title {
    padding-right: 10px;
}

.user-brand>img {
    height: 32px;
    width: auto;
}
