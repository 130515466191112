.analytics-container{
    margin: 0 -12px;

    .analytic{
        width: 100%;
        max-width: 200px;
        border-radius: 6px;
        margin: 0 12px;
        padding: 12px;

        .analytic-content{
            .count{
                color: var(--color-secondary);
            }
            .title{
                font-size: 12px;
                margin: 0;
                color: #888;
            }
        }
        .analytic-icon{
            width: 40px;
            & div {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                color: #fff;
                background-color: var(--color-red);
            }
        }
    }
}