$color: #FF0968;

.spinner-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background-color: rgba(255,255,255,.4);
    display: flex;
    justify-content: center;
    align-items: center;

    .loader-spinner {
        width: 150px;
        height: 150px;
    
        svg {
            width: 90%;
            fill: none;
        }
    }
    
    .load {
        transform-origin: 50% 50%;
        stroke-dasharray: 0.7 0.3;
        stroke-linecap: round;
        stroke-width: 3px;
        stroke: $color;
    
        &.one {
            animation: load 1.5s infinite ease-in;
            animation-direction: reverse;
        }
    
        &.two {
            fill: $color;
        }
    
        &.three {
            animation: load 1.5s infinite;
        }
    }
    
    @keyframes load {
        100% {
            transform: rotate(360deg);
        }
    }
}