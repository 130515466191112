:root {
    --color-primary: #3f51b5;
    // --color-primary: #ff0062;
    --color-secondary: #051727;
    --color-red: #ff0968;
    --color-hover:#102d49;
    // #102d49
    --color-gradient: linear-gradient(90deg, #ff0968, #8000ff);
    --light-bg: rgba(253, 242, 248, 1);
    --light-grey: #f6f6f6;
    --theme-font: "Poppins", sans-serif;
    --border-color: rgb(209, 213, 219);
    --font-size: 0.9rem;
}